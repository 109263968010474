//@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import "../css/constants";

body.login-page,
html.login-page {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.sameWidth {
  width: 100%; /* Set the desired width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px; /* Add some space between the elements */
}

@media only screen and (max-width: 600px) {

  .info-message p{

    
    padding: 15px;
    margin-top: 100px;
    margin-bottom: -180px;
    border-radius: 5px;
    font-size: 16px;
    padding: 50px;;
    color: #dadada !important;
    font-family: sans-serif;
  
    a {
      color: #007bff;
      text-decoration: none;

  
      &:hover {
        text-decoration: underline;
      }
    }
  

    
  }
  
  
  .privacy-policy {
    min-height: 100vh;
    //overflow-y: scroll; /* Enables vertical scrolling */
    padding: 30px;
    font-family: "Arial";

    .headline {
      margin-top: 10vh;
      font-size: 20px;
      color: #040404;
    }
    .subheadline {
      font-size: 18px;
      color: #040404;
    }

    .subheadline2 {
      font-size: 16px;
      color: #040404;
    }
    .application-list {
      color: #040404;
      text-align: left;
    }
  }

 
  //test registration

  .page-container {
    background-color: #1e2246;
    color: #c7c4c4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
  }

  .reg-content-container {
    text-align: center;
    width: 80%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #2c3050;
    border-radius: 8px;
  }

  .signUpText {
    margin-bottom: 20px;
  }

  .testRegistrationForm .registration-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .emailLabel {
    display: block;
  }

  .emailInput {
    width: calc(100% - 20px);
    padding: 10px;
    border: 2px solid #5865f2;
    border-radius: 5px;
    background: none;
    color: inherit;
    margin-top: 5px;
    // width: 100%;
  }

  .submitButton {
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    background-color: #5865f2;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    // width: 100%;
  }

  .submitButton:hover {
    background-color: #4348a3;
  }

  //t & c in signup
  .terms-link {
    display: inline-block;
    background-color: white;
    color: blue;
    text-decoration: none;
    padding: 0.5em 1em;
    margin: 2vh;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
  }

  .termsCondition {
    // height: 80vh;
    //overflow-y: scroll; /* Enables vertical scrolling */
    padding: 3vh;
    font-family: "Arial";

    .headline {
      margin-top: 10vh;
      font-size: 20px;
      color: #040404;
    }
    .subheadline {
      font-size: 18px;
      color: #040404;
    }

    .subheadline2 {
      font-size: 16px;
      color: #040404;
    }
    .application-list {
      color: #040404;
      text-align: left;
    }
  }

  .form-and-chat {
    display: flex !important;
    height: 100vh;
    align-items: center;
    overflow: hidden;


    .form {
      position: relative;
      z-index: 1;
      // background: #ffffff;
      max-width: 80vh;
      width: 80%;
      margin: 0 auto 100px;
      padding: 25px;
      text-align: left;
      font-family: "Arial";
      font-size: 16px;
      border-radius: 8px;

      p{
        color: map-get($colors, textbox-white);
        margin-bottom: 30px;
        margin-right: "40px";
        font-size: "16px";
  
     }

      input {
        font-family: "Arial";
        outline: 0;
        // background: #f2f2f2;
        background:  rgba(255, 255, 255, 0.1);
        color: white;
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
        border-radius: 8px;
      }
      button {
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        outline: 0;
        // background: #0669bf;
        background: rgba(255, 255, 255, 0.6);
        width: 100%;
        border: 0;
        padding: 15px;
        // color: #ffffff;
        color:  map-get($colors, button-text-grey);
        font-size: 18px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
        box-shadow: #040404;
        border-radius: 8px;
      }

      .sign-up-button{

        background: #eff2f5;
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
        margin-top: 10px;
      }
      button:hover,
      button:active,
      button:focus {
        background: #ffffff;
      }
      .message {
        margin: 15px 0 0;
        color: #e0e0e0;
        font-size: 16px;
      }
      .message a {
        color:  map-get($colors, textbox-white);
        text-decoration: none;
        font-weight: bold;
      }
      .message .password-reset {
        color:  map-get($colors, textbox-white);
        
      }
      .register-form {
        display: none;
      }
      // }
    }
  }
  .signup-form-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  

  }

  .signup-page {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 100vh;
    margin-top: 5vh;
    background-color: map-get($colors, dark-blue);
  

    .form {
      position: relative;
      z-index: 1;
      // background: #ffffff;

      max-width: 80vh;
      width: 80%;
      margin: 0 auto 100px;
      padding: 2.5vh;
      text-align: center;
      font-family: "Arial";
      font-size: 16px;
      border-radius: 8px;

      p{
        color:  map-get($colors, textbox-white);
        margin-bottom: 30px;
        margin-right: 40px;
        font-size: 16px;
      }
   

      input {
        font-family: "Arial";
        outline: 0;
        background:  map-get($colors, textbox-white-light);
        color: white;
        width: 80%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
        border-radius: 8px;
      }
      button {
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        outline: 0;
        background:  map-get($colors, textbox-white);
        width: 100%;
        border: 0;
        padding: 15px;
        color: map-get($colors, button-text-grey);
        font-size: 18px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
        box-shadow: #040404;
        border-radius: 8px;
      }
      button:hover,
      button:active,
      button:focus {
        background: white;
      }
      .message {
        margin: 15px 0 0;
        color: map-get($colors, textbox-white);
        font-size: 16px;
      }
      .message a {
        color: map-get($colors, textbox-white);
        text-decoration: none;
        font-weight: bold;
      }
      .register-form {
        display: none;
      }
    }
  }

  .button-container {
    //margin-right: 10vh;
    // margin-bottom: -3vh;
    margin-top: 5vh;
  }

  .modal {
    .termsCondition {
      height: 70vh;
      overflow-y: scroll;
      font-family: "Arial";
      //padding: 1vh;
 

      .headline {
        margin-top: 10vh;
        font-size: 20px;
        color: #040404;
      }
      .subheadline {
        font-size: 18px;
        color: 5865f2;
      }

      .subheadline2 {
        font-size: 16px;
        color: #040404;
      }
      .application-list {
        color: #040404;
        text-align: left;
      }
    }
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-actions {
    display: flex;
    justify-content: center; /* Horizontally center children */
    align-items: center; /* Vertically center if needed */
    width: 100%;

    .btn-primary,
    .btn-danger {
      border-radius: 5px;
      border: none;
      width: 12vh;
      height: 5vh;
      font-family: "Arial", sans-serif;
      margin: 0 3vh;
    }

    .btn-primary {
      background-color: #79ccd5;
    }

    .btn-danger {
      background-color: #fb7684;
    }
  }

  .modal-content {
    background: white;
    padding-left: 2vh;
    padding-right: 2vh;
    padding-bottom: 10vh;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
  }

  //signup page T&C
  .contain-conditions {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 300px;
    justify-content: flex-start;

    .checkbox-label {
      margin-left: 10px;
      margin-right: -240px;
      display: inline-block;
      color: map-get($colors, textbox-white);
      font-family: "Arial";
      width: 200px;
      white-space: nowrap;
    }

    .checkbox-field {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Define the size and make it square */
      width: 20px !important;
      height: 20px !important;

      border: 1px solid #5c3030;
      border-radius: 1px;

      &:checked {
        background-color: #0669bf;
      }

      margin-right: 10px;
    }
  }

  //pw reset page
  .pw-reset-page-container {
    background-color: #1e2246;
    color: #c7c4c4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;

    .input-field {
      border-radius: 5px;
      font-size: 100%;
      margin-bottom: 1vh;
      height: 4vh;
    }
    .pwreset-content-container {
      text-align: center;
      width: 60%;
      max-width: 400px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background: #2c3050;
      border-radius: 8px;
    }
    .message {
      color: #d1d0d0;
      margin-top: 2vh;
      // margin-top: 3vh; /* Spacing below the message */
      // font-size: 1rem; /* Default font size */
    }
  }
}

/* error message for login/signup */
.errorMessage {
  color:  map-get($colors, textbox-white);
  margin-bottom: 5px;
  margin-right: 20px;
  margin-top: 20px;
  font-family: "Arial";
  font-size: 16px;
}

@media only screen and (min-width: 601px) and (max-width: 1400px) {
  .privacy-policy {
    min-height: 100vh;
    //overflow-y: scroll; /* Enables vertical scrolling */
    padding: 30px;
    font-family: "Arial";

    .headline {
      margin-top: 10vh;
      font-size: 20px;
      color: #040404;
    }
    .subheadline {
      font-size: 18px;
      color: #040404;
    }

    .subheadline2 {
      font-size: 16px;
      color: #040404;
    }
    .application-list {
      color: #040404;
      text-align: left;
    }
  }



  //test registration

  .page-container {
    background-color: #1e2246;
    color: #c7c4c4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
  }

  .reg-content-container {
    text-align: center;
    width: 80%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #2c3050;
    border-radius: 8px;
  }

  .signUpText {
    margin-bottom: 20px;
  }

  .testRegistrationForm .registration-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .emailLabel {
    display: block;
  }

  .emailInput {
    width: calc(100% - 20px);
    padding: 10px;
    border: 2px solid #5865f2;
    border-radius: 5px;
    background: none;
    color: inherit;
    margin-top: 5px;
  }

  .submitButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #5865f2;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .submitButton:hover {
    background-color: #4348a3;
  }

  //t & c in signup
  .terms-link {
    display: inline-block;
    background-color: white;
    color: blue;
    text-decoration: none;
    padding: 0.5em 1em;
    margin: 2vh;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
  }

  .termsCondition {
    //height: 80vh;
    //overflow-y: scroll; /* Enables vertical scrolling */
    padding: 4vh;
    font-family: "Arial";

    .headline {
      margin-top: 10vh;
      font-size: 20px;
      color: #040404;
    }
    .subheadline {
      font-size: 18px;
      color: #040404;
    }

    .subheadline2 {
      font-size: 16px;
      color: #040404;
    }
    .application-list {
      color: #040404;
      text-align: left;
    }
  }

  .form-and-chat {
    display: flex !important;
    height: 100vh;
    align-items: center;
    overflow: hidden;


    .form {
      position: relative;
      z-index: 1;
      // background: #ffffff;
      max-width: 80vh;
      width: 60%;
      margin:  auto 100px;
      // margin-left: 50%;
      padding: 25px;
      text-align: center;
      font-family: "Arial";
      font-size: 16px;
      border-radius: 8px;

      input {
        font-family: "Arial";
        outline: 0;
        background:  rgba(255, 255, 255, 0.1);
        color:  map-get($colors, textbox-white);
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
        border-radius: 8px;
      }
      button {
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        outline: 0;
        background:  map-get($colors, textbox-white);
        width: 100%;
        border: 0;
        padding: 15px;
        color:  map-get($colors, button-text-grey);
        font-size: 18px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
        box-shadow: #040404;
        border-radius: 8px;
      }

      .sign-up-button{

        background: #eff2f5;
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
        margin-top: 10px;
      }
      button:hover,
      button:active,
      button:focus {
        background: #30c8d9;
      }
      .message {
        margin: 15px 0 0;
        color: #0669bf;
        font-size: 16px;
      }
      .message a {
        color: #e63d37;
        text-decoration: none;
        font-weight: bold;
      }

      .message .password-reset {
        color:  map-get($colors, textbox-white);
       //margin-left: 2vh;
      }
    }
    .register-form {
      display: none;
    }
    // }
  }

  .signup-form-containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
  }

  .signup-page {
    position: relative;

    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-height: 100%;
    background-color: map-get($colors, dark-blue);

    .form {
      position: relative;
      z-index: 1;
      // background: #ffffff;
      max-width: 80vh;
      width: 70%;
      margin: 0 auto 100px;
      padding: 2.5vh;
      //text-align: left;
      font-family: "Arial";
      font-size: 16px;
      border-radius: 8px;

      p{
        color:  map-get($colors, textbox-white);
        margin-bottom: 30px;
        margin-right: 40px;
        font-size: 16px;
      }
   

      input {
        font-family: "Arial";
        outline: 0;
        background:  map-get($colors, textbox-white-light);
        color: white;
        //width: 70%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        box-sizing: border-box;
        font-size: 14px;
        border-radius: 8px;
      }
      button {
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        outline: 0;
        background:  map-get($colors, textbox-white);
       // width: 70%;
        border: 0;
        padding: 15px;
        color: map-get($colors, button-text-grey);
        font-size: 18px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
        box-shadow: #040404;
        border-radius: 8px;
      }
      button:hover,
      button:active,
      button:focus {
        background: white;
      }
      .message {
        margin: 15px 0 0;
        color: map-get($colors, textbox-white);
        font-size: 16px;
      }
      .message a {
        color: map-get($colors, textbox-white);
        text-decoration: none;
        font-weight: bold;
      }
      .register-form {
        display: none;
      }
    }
  }

  .button-container {
    //margin-right: 10vh;
    // margin-bottom: -3vh;
    margin-top: 5vh;
  }

  .modal {
    // position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;

       .termsCondition {
       height: 70vh;
        overflow-y: scroll;
        padding: 3vh;
        font-family: "Arial";

        .headline {
          margin-top: 10vh;
          font-size: 20px;
          color: #040404;
        }
        .subheadline {
          font-size: 18px;
          color: #040404;
        }

        .subheadline2 {
          font-size: 16px;
          color: #040404;
        }
        .application-list {
          color: #040404;
          text-align: left;
        }
      }
  }

  .modal-actions {
    display: flex;
    justify-content: center; /* Horizontally center children */
    align-items: center; /* Vertically center if needed */
    width: 100%;

    .btn-primary,
    .btn-danger {
      border-radius: 5px;
      border: none;
      width: 12vh;
      height: 5vh;
      font-family: "Arial", sans-serif;
      margin: 0 3vh;
    }

    .btn-primary {
      background-color: #79ccd5;
    }

    .btn-danger {
      background-color: #fb7684;
    }
  }

  .modal-content {
    background: white;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 15vh;
    border-radius: 5px;
    //max-width: 500px;
    width: 100%;
  }

  .contain-conditions {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 300px;
    justify-content: flex-start;

    .checkbox-label {
      margin-left: 10px;
      margin-right: -240px;
      display: inline-block;
      // color: black;
      color: map-get($colors, textbox-white);
      font-family: "Arial";
      width: 200px;
      white-space: nowrap;
    }

    .checkbox-field {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Define the size and make it square */
      width: 20px !important;
      height: 20px !important;

      border: 1px solid #5c3030;
      border-radius: 1px;

      &:checked {
        background-color: #0669bf;
      }

      margin-right: 10px;
    }

    //reset pw page
    .reset-form-container {
      padding: 10px; /* Lesser padding for smaller screens */
      min-height: 100vh; /* Maintaining full viewport height */
      box-sizing: border-box; /* Include padding in the width calculation */
    }

    .reset-form-container form {
      width: 60%; /* Reduce width to give some side space */
    }

    .input-field,
    .submit-button {
      width: 100%; /* Full width to use the available space */
    }

    .pwreset-background {
      display: flex;
      overflow: hidden;
    }
    .reset-password-container {
      //margin: 20px auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 4px;
      background-color: #1e2246;
      display: flex;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: Arial;
      color: #c7c4c4;

      .reset-password-container h2 {
        text-align: center;
        margin-bottom: 20px;
      }

      .reset-password-form {
        display: flex;
        flex-direction: column;
      }

      .reset-password-form div {
        margin-bottom: 15px;
      }

      .reset-password-form label {
        display: block;
        margin-bottom: 5px;
      }

      .reset-password-form input {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }

      .reset-password-form button {
        cursor: pointer;
        width: 100%;
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        transition: background-color 0.3s ease;
      }

      .reset-password-form button:hover {
        background-color: #0056b3;
      }

      .message {
        color: #d1d0d0; /* Default text color */
        margin-top: 3vh; /* Spacing below the message */
        font-size: 1rem; /* Default font size */
      }

      .error {
        color: #dadada; /* Error color, often a shade of red */
        font-weight: bold; /* Makes the error message more prominent */
      }
    }
  }

  .pw-reset-page-container {
    background-color: #1e2246;
    color: #c7c4c4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;

    .input-field {
      border-radius: 5px;
      margin-bottom: 2vh;
      height: 4vh;
      font-size: 150%;
      width: 100%;
    }
    .pwreset-content-container {
      text-align: center;
      width: 80%;
      max-width: 400px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background: #2c3050;
      border-radius: 8px;
    }

    .message {
      color: #d1d0d0;
      margin-top: 2vh;
      // margin-top: 3vh; /* Spacing below the message */
      // font-size: 1rem; /* Default font size */
    }
  }

  .page-container {
    background-color: #1e2246;
    color: #c7c4c4;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
  }

  .reg-content-container {
    text-align: center;
    width: 80%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #2c3050;
    border-radius: 8px;
  }

  .signUpText {
    margin-bottom: 20px;
  }

  .testRegistrationForm .registration-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .emailLabel {
    display: block;
  }

  .emailInput {
    width: calc(100% - 20px);
    padding: 10px;
    border: 2px solid #5865f2;
    border-radius: 5px;
    background: none;
    color: inherit;
    margin-top: 5px;
  }

  .submitButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #5865f2;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }

  .submitButton:hover {
    background-color: #4348a3;
  }
}

@media (max-width: 600px) {
  .reset-form-container {
    padding: 10px; /* Lesser padding for smaller screens */
    min-height: 100vh; /* Maintaining full viewport height */
    box-sizing: border-box; /* Include padding in the width calculation */
  }

  .reset-form-container form {
    width: 60%; /* Reduce width to give some side space */
  }

  .input-field,
  .submit-button {
    width: 100%; /* Full width to use the available space */
  }
}
.reset-password-container {
  //margin: 20px auto;
  //padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #1e2246;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Arial;
  color: #c7c4c4;

  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .reset-password-form {
    display: flex;
    flex-direction: column;
  }

  .reset-password-form div {
    margin-bottom: 15px;
  }

  .reset-password-form label {
    display: block;
    margin-bottom: 5px;
  }

  .reset-password-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .reset-password-form button {
    cursor: pointer;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }

  .reset-password-form button:hover {
    background-color: #0056b3;
  }

  .message {
    color: #d1d0d0; /* Default text color */
    margin-top: 3vh; /* Spacing below the message */
    font-size: 1rem; /* Default font size */
  }

  .error {
    color: #dadada; /* Error color, often a shade of red */
    font-weight: bold; /* Makes the error message more prominent */
  }
}

.centered-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Use fixed to make sure it covers the entire viewport */
  top: 0;
  left: 0;
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Optional: background color with opacity */
  z-index: 1000; /* High z-index to make sure it's on top of other content */
}

.block-message {
  text-align: center; /* Center text within the block */
  width: 90%; /* Set a max width to the inner content */
  max-width: 600px; /* Optional: Constrain max width for better readability */
  padding: 20px; /* Padding for aesthetics */
  background: white; /* Background of the message, contrast with overlay */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: shadow for visual depth */
}

.block-message p {
  margin: 16px 0; /* Space out paragraphs */
}


@media (orientation: landscape) {

  .form-and-chat{
    .form{
      width: 80%;
      top:-60vh;
      margin-left: 60vh;
    }
  }
  
}

.loading-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 1000; /* Make sure it's on top of other content */

.loading-popup {
  position: fixed; // Position fixed to cover the whole screen
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent black background

  .loading-content {
    padding: 20px 40px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    p {
      margin: 0;
      font-size: 16px;
      color: #333;
    }
  }
}
}