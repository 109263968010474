@font-face {
  font-family: "Phenomena";
  src: url("../assets/fonts/Phenomena-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bradley Hand";
  src: url("../assets/fonts/BradleyHandITCTT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


$primary-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$colors: (

  light-background: #dad8d6,
  aqua: #79ccd5,
  dark-blue: #162133,
  foot-note-blue: #1e2246,
  lighter-grey: #e1d6d6,
  yellow: #e7c30b,
  payment-tab: rgba(0, 97, 242, 0.2),
  title-green: #096278,
  sub-title-green: #6f8d94,
  textbox-white: rgba(255, 255, 255, 0.8),
  button-text-grey:#5e5e5e,
  textbox-white-light: rgba(255, 255, 255, 0.2),
  background-blue:#1e2246,
  text-grey:#4b4a4a,



);


