
@import "../css/constants";
:root {
  // --body-bg: linear-gradient(
  //   100deg,
  //   #07b2d9,
  //   #30c8d9 40%,
  //   #f2dec4 100%
  // );

  // --msger-bg: #fff;
  // --border: 2px solid #ddd;
  // --left-msg-bg: #ececec;
  // --right-msg-bg: #22a699;
  --left-msg-bg: radial-gradient(circle at bottom left,  #ebeed5, #f8d8da); //plan 1
  // --right-msg-bg: radial-gradient(circle at ,   #ebeed5,   #07b2d9); //plan 1

 // --right-msg-bg:  #07b2d9;
 --right-msg-bg: #478CCF;
}

.language-dropdown-container {
  position: fixed;
  //top: 10px; // Adjust as per your header's height
  right: 20vw;
  z-index: 1000; // Ensure it's above other elements



    .language-label {
        margin-right: 10px; // Adds spacing between label and dropdown
        white-space: nowrap; // Keeps the label on a single line
        color: #1e2246;
        font-weight: bold;
    }

  .language-dropdown {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #ccc;
  }
  

}
@media only screen and (max-width: 600px) {
  .body_chat {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-family: "Arial";
    position: relative;


  }

  .chatbot-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
   overflow: hidden;
    margin: 0;
    // width: 100%;

    .button-containter {
      position: relative;
      font-size: 14px;
      text-align: center;
      bottom: 9vh;
      z-index: 1050;
    }

    .toggle-container {
      position: relative;
      width: 80px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
      right: -145vh;
      bottom: -10vh;
      overflow: hidden;
      font-family: "Arial";
      transition: background-color 0.3s;

      &.mode-compact {
        background-color: #c8d0dd;
      }

      &.mode-wide {
        background-color: #29b5a8;
      }

      .label-left,
      .label-right {
        position: absolute;
        font-weight: bold;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 14px;
        font-family: "Arial";
      }

      .label-left {
        left: 10px;
      }

      .label-right {
        right: 10px;
      }
    }

    .toggle-theme-btn {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: white;
      border: none;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.compact {
        left: calc(100% - 60px);
      }

      &.wide {
        left: calc(100% - 20px);
      }
    }
  }



  .chatbot-page::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height:100%;
    background: linear-gradient(
    -100deg,
    #07b2d9,
    #30c8d9 40%,
    #f2dec4 100%
  );
    background-size: 100% 100%;
    opacity: 0.7; 
    pointer-events: none; 
    transform: scaleX(-1);
  }

  .msger.compact {
    max-width: 80vh;
    margin: 50px auto;
  }

  .msger {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    //height: 100vh;
    min-height: 100vh;
    border: var(--border);
    background: var(--msger-bg);
    overflow: hidden;
    padding: 1%;
    z-index: 2;

    /* New .compact class adjusting the .msger styling for compact mode */

    .msger-header {
      position: fixed;
      font-size: medium;
      justify-content: space-between;
      padding: 10px;
      text-align: center;
      border-bottom: var(--border);
      // background: #eee;
      background:transparent;
      color: #07b2d9;
      font-family: "Arial";
      font-size: 18px;
      z-index: 10;
    }
    .msger-chat {
      flex: 1;
      overflow-y: auto;
      //padding: 10px;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #ddd;
      }
      &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
      }
    }
  }

  .msger-inputarea {
    display: flex;
    padding: 15px;
    border: none;
    background:transparent;
    font-family: "Arial";
    justify-content: space-between;
    z-index:1000;

    .msger-input {
      flex: 1;
      padding: 10px;
      resize: none;
      overflow-y: auto;
      border: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.2);

      &::placeholder {
        color: map-get($colors, button-text-grey);
        opacity: 1;
      }
   
    }

    .msger-input:focus {
      outline: 2px solid rgba(255, 255, 255, 0.5);
      outline-offset: 2px;
    }


    .msger-send-btn {
      margin-left: 10px;
      margin-right: 10px;
      // background: #22a699;
      background: radial-gradient(circle at bottom left,  #07b2d9, #667BC6);
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      height: 55px;
      padding: 10px 10px;
      border: none;
      border-radius: 8px;
      box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
   
      
    }
  }
  .tacbox {
    display: block;
    padding: 2em;
    //margin: 2em;
    //border: 3px solid #ddd;
    background-color: #eee;
    // max-width: 800px;
    width: 80%;
    border-radius: 8px;
    font-size: 2vh;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;

    p {
      font-family: "Arial";
      font-size: 18px;
    }

    .agree-btn {
      margin-top: 10px;
      background: #0678bf;
      color: #fff;
      width: 5em;
      height: 2em;
      border: none;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      border-radius: 8px;
    }
  }
}
/* Adjustments for Mobile Devices */
// @media (max-width: 600px) {
// .msger-header,
// .msger-chat {
//   padding: 5px;
//   width: 100%;
//   max-width: 1200px; /* This can be adjusted based on design requirements */
//   margin: 0 auto;
// }

// .msger-chat {
//   height: calc(
//     100vh - 120px
//   ); /* Adjust 120px based on other elements' total height */
// }
// }

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

/*------------- message bubble style ---------- */
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  .msg-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }
}

.msger-chat {
  // background-color: #fcfcfe;
  background: transparent;
}

.msg-bubble {
  max-width: 75vw;
  padding: 15px;
  border-radius: 15px;
  // background: var(--left-msg-bg);
  background: rgba(255, 255, 255, 0.3);
  margin-top: 7vh;
  font-family: "Arial";
  font-size: 16px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);

  .msg-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .msg-info-name {
    margin-right: 10px;
    font-weight: bold;
  }
  .msg-info-time {
    font-size: 0.85em;
  }
}

.msger.compact .msg-bubble {
  font-size: 16px;
}

.right-msg {
  flex-direction: row-reverse;

  .msg-bubble {
    // background: var(--right-msg-bg);
    background: rgba(255, 255, 255, 0.8);
    color: black;
    // color: black;
    border-bottom-right-radius: 0;
  }

  .msg-img {
    margin: 0 0 0 10px;
  }
}

/*------------- api input area ---------- */
.api-inputarea {
  display: flex;
  padding: 10px;
  border-top: var(--border);
  background: #eee;

  * {
    padding: 10px;
    width: 250px;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    font-family: "Arial";
  }

  .api-input {
    flex: 1;
    background: #ddd;
  }

  .api-send-btn {
    margin-left: 10px;
    background: #0678bf;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.23s;
  }
  .api-send-btn:hover {
    background: #30c8d9;
  }
}

/*------------- agreement component ---------- */

/*  rating and feedback */
.ratings img {
  cursor: pointer;
  transition: all 0.5s;
  height: 20px;
  float: left;
}

.ratings img:hover {
  transform: scale(1.3);
}

.rating-img {
  font-size: 0;
}

.feedback-inputarea {
  margin-top: 5px;
}

.feedback-inputarea input {
  height: 20px;
  width: 40%;
}

.send-correct {
  margin-left: 5px;
  background: rgb(229, 227, 225);
  color: #0e0e0e;
  cursor: pointer;
  transition: background 0.23s;
  margin-bottom: 5px;
}

/*Defining the thumb button in feedback form */
.rating-img {
  height: 20px;
  width: auto;
}

.rating-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.rewrite-textarea {
  width: 500px;
  width: 90%;
}

/*------------- profile page, summary container -----------*/
.profile-background {
  // background: linear-gradient(100deg, #07b2d9, #30c8d9 40%, #f2dec4 100%);
  background: #171c3e;
  min-height: 100vh;
  overflow-x: hidden;
  

  .wait-list-text p {
    position: absolute; /* Change to absolute */
    top: 50%; /* Change from bottom to top */
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    font-size: 25px;
    font-family: "Arial";
    text-align: center;
    color: #ffffff;
  }

  .summary-form-container {
    margin-top: 5vh;
    max-width: 1400px;
    //height: 400vh;
    min-height: 100vh;
    overflow-y: auto;
    margin-left: auto; /* Centers the container horizontally */
    margin-right: auto;

    p {
      color: #bdbdbd;
      

    }

    .summary-container {
      margin: 10px;
      margin-top: auto;
      margin-bottom: 10px;
      // margin-left: 100px;
      // margin-right: 600px;
      // border: 1px solid #ffe0d1;
      border-radius: 5px;
      padding: 30px;
      background-color: #1e2246;

      .summary-header {
        cursor: pointer;
        font-weight: bold;
        font-family: "Arial";
      }
    }
    .summary-text {
      font-size: 16px;
      margin-top: 3vh;
      margin-left: 2vh;
      font-family: "Helvetica";
      color: #cac8c8;
      line-height: 2.5vh;
    }

    .summary-time {
      color: #ffffff;
    }

    p {
      font-size: 20px;
      margin-left: 30%;
    }
  }

  .summary-btn-container {
    display: flex;
    justify-content: center;
    // align-items: left;
    gap: 50px;
    // margin-right: 700px;
    // margin-top: 50px;

    .summary-btn {
      margin-top: 100px;
      background: #4356d6;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      height: 45px;
      padding: 10px 10px;
      border-radius: 10px;
      border: none;
      box-shadow: none;
      font-family: "Arial";
    }
  }
}

.credits-tab {
  // position: relative;
  margin-top: 20px;
  // margin-right: 200px;
  // margin-left: 1000px;
  background-color: #1e2246;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 200px;
  margin-left: auto; /* Centers the container horizontally */
  margin-right: auto;

  .font {
    color: white;
    font-size: 18px;
    line-height: 5vh;
    font-family: "Helvetica";
  }
}

@media only screen and (min-width: 601px) and (max-width: 1400px) {


  .body_chat {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    // background-image: var(--body-bg);
    background: linear-gradient(
    -100deg,
    #07b2d9,
    #30c8d9 40%,
    #f2dec4 100%
  );
    font-family: "Arial";
  }

  .chatbot-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 1vh;

    .button-containter {
      position: relative;
      font-size: 14px;
      text-align: center;
      bottom: 9vh;
      z-index: 1050;
    }

    .toggle-container {
      position: relative;
      width: 80px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
      right: -145vh;
      bottom: -10vh;
      overflow: hidden;
      font-family: "Arial";
      transition: background-color 0.3s;

      &.mode-compact {
        background-color: #c8d0dd;
      }

      &.mode-wide {
        background-color: #29b5a8;
      }

      .label-left,
      .label-right {
        position: absolute;
        font-weight: bold;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 14px;
        font-family: "Arial";
      }

      .label-left {
        left: 10px;
      }

      .label-right {
        right: 10px;
      }
    }

    .toggle-theme-btn {
      position: absolute;
      width: 30px;
      height: 30px;
      background-color: white;
      border: none;
      border-radius: 50%;
      transition: 0.3s;
      cursor: pointer;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.compact {
        left: calc(100% - 60px);
      }

      &.wide {
        left: calc(100% - 20px);
      }
    }
  }

  .msger.compact {
    max-width: 80vh;
    margin: 50px auto;
  }
  .msger {
    display: flex;
    justify-content: space-between;
    flex-flow: column wrap;
    min-height: 100vh;
    // min-height: 100vh;
    border: none;
    background: var(--msger-bg);
    background: transparent;
    overflow: hidden;
    width: 100vw;
    //padding: 1%;
    z-index: 2;

    /* New .compact class adjusting the .msger styling for compact mode */

    .msger-header {
      position: fixed;
      font-size: medium;
      justify-content: space-between;
      padding: 10px;
      text-align: center;
      border-bottom: var(--border);
      // background: #eee;
      color: #07b2d9;
      background:transparent;
 
      font-family: "Arial";
      font-size: 18px;
      z-index: 10;
    }
    .msger-chat {
      flex: 1;
      overflow-y: auto;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: #ddd;
      }
      &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
      }
    }
  }

  .msger-inputarea {
    display: flex;
    padding: 15px;
    border: none;
    background: transparent;
    font-family: "Arial";
    justify-content: space-between;

    .msger-input {
      flex: 1;
      padding: 10px;
      resize: none;
      overflow-y: auto;
      border: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.2);
      &::placeholder {
        color: map-get($colors, button-text-grey);
        opacity: 1;
      }
   
    }

    .msger-input:focus {
      outline: 2px solid rgba(255, 255, 255, 0.5);
      outline-offset: 2px;
    }

    .msger-send-btn {
      margin-left: 10px;
      margin-right: 10px;
      background: map-get($colors, textbox-white);
      color: map-get($colors, button-text-grey);
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      height: 55px;
      padding: 10px 10px;
      border: none;
      border-radius: 8px;
    }
  }

  .tacbox {
    display: block;
    padding: 2em;
    //margin: 2em;
    //border: 3px solid #ddd;
    background-color: #eee;
    // max-width: 800px;
    width: 90%;
    border-radius: 8px;
    font-size: 2vh;
    font-family: Arial;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10vh;

    p {
      font-family: "Arial";
      font-size: 18px;
    }

    .agree-btn {
      margin-top: 10px;
      background: #0678bf;
      color: #fff;
      width: 5em;
      height: 2em;
      border: none;
      font-weight: bold;
      cursor: pointer;
      transition: background 0.23s;
      border-radius: 8px;
    }
  }
}


.agree-tacbox {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top:10vh;
  font-family: Arial;
}

.indented-list {
  padding-left: 5vh;
}

.agree-tacbox p,
.agree-tacbox ul {
  margin-bottom: 10px;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-top: 4px;
  flex-shrink: 0;
}

.checkbox-container label {
  margin-left: 20px;
  flex-grow: 1;
  word-wrap: break-word;
  line-height: 1.5;
  margin-right: -30vh;
}

.agree-btn-container {
  text-align: center;
  margin-top: 20px;
}

.agree-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (orientation: landscape) {

  .profile-background{
    width: 100vw;

    .summary-form-container {
      .summary-text{
        line-height: 5vh;
      }
    }
    
  }

  .msger{
    margin-top: 10vh;
  }

  .pwreset-background{
    width: 100vw;
  }
  
}