@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Phenomena";
  src: url("../src/assets/fonts/Phenomena-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

}

@font-face {
  font-family: "Bradley Hand";
  src: url("../src/assets/fonts/BradleyHandITCTT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$colors: (
  dark-blue: #162133,
  light-background: #dad8d6,
  aqua: #79ccd5,
);

#buttonid::-moz-focus-inner,
.buttonclass::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}



/* front page color */
.header {
  position: relative;
  text-align: center;
  line-height: 1.45;
  background: map-get($colors, dark-blue);
  background-size: cover;
  color: rgb(255, 255, 255);
  overflow: visible;
  // overflow: hidden;
 
}


.float-header {
  position: fixed;
  background: map-get($colors, dark-blue);
  background-size: cover;
  color: rgb(255, 255, 255);
  // overflow: hidden;
  top:0;
  height:8vh;
  width: 100%;
  z-index: 100;
}


.chatButton-login {
  position: relative;
  right: 7%;
  bottom: 25%;
  display: flex;
}

.container .btn-holder {
  position: relative;
  text-align: center;
  padding-right: 0;
  padding-top: 0;
  vertical-align: middle;
  display: inline-block;
  margin-top: 3em;
  max-width: 5vh;
  min-width: 1vh;
}

/*----------button position ----------*/
.pulse-button {
  position: relative;
  width: 12vh;
  height: 12vh;
  //margin-top: -5em;
  padding:10px;
  display: block;
  font-size: 100%;
  font-weight: bold;
  font-style: normal;
  font-family: "Verdana";
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0px;
  color: #096278;
  border: none;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.596);
  /* animation: pulse 2s infinite; */
}

.pulse-button:hover {
  -webkit-animation: pulse 2s infinite;
}

p {
  color: #333333;
  text-align: justify;
}


/*---------- Animation wave ----------*/

@keyframes waves {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.waves {
  position: relative;
  width: 100%;
  height: 5vh;
  margin-bottom: 0 px;
  min-height: 160px;
  margin-top: -45em;

  .parallax > use {
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
}

/*---------- front page footer ----------*/
.front-page-footer {
  flex-shrink: 0;
}
.front-footer {
  position: relative;
  height: 12vh;
  text-align: center;
  flex-direction: column;
  font-family:  sans-serif;
  font-size: 1rem;
  // padding-top: 5vh;
  background-color: #1e2246;
  padding: 20px 10px;
  line-height: 1.8;

  .footer-content {
    display: flex;
    justify-content:  space-between;
    align-items: center;
    max-width: 1200px;
    margin-bottom: 1vh;
    // flex-wrap: wrap;
    margin: 0 auto; /* Center the content */
    // padding-bottom: 20px;



    .footer-link {
      margin-bottom: 0.5vh;
      color: #ffffff;
      text-decoration: none;
    }
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    margin-right: 10vw;
  }

  .footer-column a {
    margin-bottom: 0.5vh;
    color: rgb(255, 255, 255);
  }

  .footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 4vh;
    height: 5vh;
    margin-bottom: 1vh;
    // padding-bottom: 1px;

    p {
      color: #474748;
      font-size: 14px;
    }
  }

  .footer-logo {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .footer-logo img {
    height: 8vh; /* Adjust the height as needed */
    border-radius: 8px;
  }
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Standard keyframes for non-WebKit browsers */
@keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 244, 244, 0.963);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(254, 254, 254, 0.95);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.933);
  }
}

/*pay attention to whether this is css style, not scss*/
@-webkit-keyframes pulse {
  0% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(245, 244, 244, 0.963);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 5px 15px rgba(254, 254, 254, 0.95);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.933);
  }
}

* {
  margin: 0;
  padding: 0;
}
i {
  margin-right: 10px;
}
/*----------bootstrap-navbar-css------------*/

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  #navbarSupportedContent li .nav-link {
    // position: fixed;
    background: "transparent";
    font-size: 16px;
    color: #096278;
    font-family: Verdana;
    font-weight: bold;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    cursor: pointer;
    border: none;
    text-decoration: none;
  }

  .active {
    background-color: #79ccd5;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  li a:hover:not(.active) {
    background-color: #79ccd5;
  }

  .nav-item {
    padding: auto;
  }

  #navbarSupportedContent {
    overflow: hidden;
    position: relative;
  }
  #navbarSupportedContent ul {
    padding: 0px;
    margin: 0px;
  }
  #navbarSupportedContent ul li a i {
    margin-right: 10px;
  }
  #navbarSupportedContent li {
    list-style-type: none;
    float: left;
  }
  #navbarSupportedContent ul li a {
    color: #096278;
    text-decoration: none;
    font-size: 15px;
    font-family: "Verdana";
    font-weight: bold;
    display: block;
    padding: 20px 20px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  #navbarSupportedContent > ul > li.active > a {
    color: #30c8d9;
    background-color: transparent;
    transition: all 0.7s;
  }
  #navbarSupportedContent a:not(:only-child):after {
    content: "\f105";
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 14px;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    transition: 0.5s;
  }
  #navbarSupportedContent .active > a:not(:only-child):after {
    transform: rotate(90deg);
  }
}




/*--------------------- Existing Mobile Query: Adjusted for better clarity ----------------------*/

@media only screen and (max-width: 600px) {
  // header

  #menuToggle {
    display: block;
    position: fixed;
    top: 15px;
    left: 5vh;
    z-index: 3;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #ffffff;

    transition: color 0.3s ease;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    z-index: 2;

    -webkit-touch-callout: none;
  }

  #menuToggle span {
    display: block;
    width: 4vh;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #menu {
    position: absolute;
    width: 20vh;
    height: 100vh;
    margin: -100px 0 0 -50px;
    padding: 20px;
    padding-top: 20vh;
    font-family: "Arial";

    background: #162133;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    border-radius: 10px;

    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.9),
      0 6px 20px rgba(255, 255, 255, 0.8); /* X-offset, Y-offset, Blur radius, Color */
  }

  #menu li {
    padding: 25px 0;
    font-size: 18px;
    margin-left: 4vh;
    color: #162133;
    font-weight: bold;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .waves {
    height: 10vh;
    min-height: 10vh;
    z-index: 2;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }

  .pulse-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13vh;
    height:13vh;
    top:-5vh;

  }

  .main-container {
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;

 
    .inner-header {
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-top: 5vh;
      align-items: center;
      min-height: 100vh;
      background: #162133;

    }

    #maintitle {
      position: relative;
      font-size: 8vh;
      margin-bottom: -20vh;
      z-index: 2;
      font-family: "Phenomena";
      top: 65vh;
    }
    #subtitle {
      font-family: "Bradley Hand";
      color: #f7ffc7;
      position: relative;
      font-size: 7vw;
      //left: 50%;
      transform: translateX(-45%);
      z-index: 2;
      // top: 30vh;
      top: 15vh;
      left: 50%;
    }

    .title-container {
      position: relative;
      margin-left: 2vh;
      margin-top: 0;
      align-items: center;
      flex-direction: column;
    }
  }

  .face-photo {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: 5vh;
    top: 10vh;
    margin-bottom: 20px;
    //z-index: 1;
  }

  .chatButton-container-main {
    z-index: 100;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  


    #subtitle {
      text-align: center;
      margin-top: 2vh;
      width: 100%;
      display: flex;
      margin-left: 50vh;
      margin-left: 90%;

    }
  }


  .blocks-container {
    display: flex;
    flex-direction: column;
    background-color: #dad8d6;

    .brain-image {
      height: 20vh;
      width: 70vh;
    }

    .block {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 20px;
      flex-direction: column;

      .text-area {
        max-width: 600px; /* Restrict width for better readability */
        padding: 20px; /* Add inner spacing */
        background-color: #ffffff; /* Optional: Add a background */
        border-radius: 10px; /* Rounded corners */
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
        text-align: left;
        color: #333; /* Darker color for better contrast */
        font-size: 16px;
        font-family: 'Arial', sans-serif;
        line-height: 1.6; /* Increase line spacing for readability */
        margin-bottom: 20px; /* Space between text and next content */
      }
  
  

      // .text-area {
      //   flex-basis: 50%;
      //   text-align: left;
      //   color: black;
      //   font-size: 18px;
      //   font-family: Arial;
      // }

      img {
        width: 80%;
        height: auto;
        padding: 20px;
        border-radius: 25px;

      }
    }

    .block-profile {
      background-color: #ffffff;
    }

    .block-memory {
      background-color: #ffffff;
    }

    .block-strategy {
      background-color: #ffffff;
    }
  }
}

/* New Media Query for Tablet */
@media only screen  and (min-width: 601px) and (max-width: 1400px)  {
  #menuToggle {
    display: block;
    position: fixed;
    top: 15px;
    left: 5vh;
    z-index: 3;
    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #ffffff;

    transition: color 0.3s ease;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    z-index: 2;

    -webkit-touch-callout: none;
  }

  #menuToggle span {
    display: block;
    width: 4vh;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #menu {
    position: absolute;
    width: 15vh;
    height: 100vh;
    margin: -100px 0 0 -50px;
    padding: 30px;
    padding-top: 15vh;
    font-family: "Arial";

    background: #162133;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    border-radius: 10px;

    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.9),
      0 6px 20px rgba(255, 255, 255, 0.8); /* X-offset, Y-offset, Blur radius, Color */
  }

  #menu li {
    padding: 30px 0;
    font-size: 18px;
    margin-left: 4vh;
    color: #162133;
    font-weight: bold;
  }

  #menuToggle input:checked ~ ul {
    transform: none;
  }

  .waves {
    height: 10vh;
    min-height: 10vh;
    z-index: 2;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }

  .pulse-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-container {
    flex-direction: column;
    // height: 100vh;
    //justify-content: center;
    min-height: 100vh;
    align-items: center;
    display: flex;
    background:#162133;

    .inner-header {
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-top: 10vh;
      align-items: center;
    }

    #maintitle {
      position: relative;
      font-size: 12vh;
      margin-bottom: -20vh;
      z-index: 2;
      font-family: "Phenomena";
      top: 65vh;
    }
    #subtitle {
      font-family: "Bradley Hand";
      color: #f7ffc7;
      position: relative;
      font-size: 7vw;
      //left: 50%;
      transform: translateX(-45%);
      z-index: 2;
      // top: 30vh;
      top:-10vh;
      left:50%;
    }

    .title-container {
      position: relative;
      margin-left: 2vh;
      margin-top: 0;
      align-items: center;
      flex-direction: column;
    }

    .chatButton-container-main {
      z-index: 2;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      margin-top: -30vh;

      #subtitle {
        text-align: center;
        margin-top: 2vh;
        width: 100%;
        display: flex;
        margin-left: 50vh;
        margin-left: 90%;
        top: 15vh;
      }
    }
  }

  .face-photo {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: 5vh;
    top: 4vh;
    z-index: 1;
  }

  .blocks-container {
    display: flex;
    flex-direction: column;
    background-color: #dad8d6;

    .brain-image {
      height: 20vh;
      width: 70vh;
    }

    .block {
      display: flex;
      align-items: center;
      justify-content: space-around;
      // padding: 20px;
      flex-direction: column;
      width: 100%; /* Ensure content doesn't overflow */


    .text-area {
      max-width: 600px; /* Restrict width for better readability */
      padding: 20px; /* Add inner spacing */
      background-color: #ffffff; /* Optional: Add a background */
      border-radius: 10px; /* Rounded corners */
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
      text-align: left;
      color: #333; /* Darker color for better contrast */
      font-size: 16px;
      font-family: 'Arial', sans-serif;
      line-height: 1.6; /* Increase line spacing for readability */
      margin-bottom: 20px; /* Space between text and next content */
    }



      // .text-area {
      //   flex-basis: 50%;
      //   text-align: left;
      //   margin: 0 auto;
      //   color: black;
      //   font-size: 18px;
      //   font-family: sans-serif;
      //   max-width: 50%;

   
      // }

      img {
        width: 60%;
        height: auto;
        padding: 10px;
      }
    }

    .block-profile {
      background-color: #ffffff;
    }

    .block-memory {
      background-color: #ffffff;
    }

    .block-strategy {
      background-color: #ffffff;
    }
  }
}


