.team_image {
  float: left;
  margin-right: 20px;
}

.introduction,
.location,
.questions,
.content-footer {

  padding: 1em;
  margin-top: 10vh;
}

.masthead-intro,
.masthead-heading,
.content-footer > p,
.social {
  text-align: center;
}

.introduction,
.location,
.questions {
  max-width: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.introduction,
.research-style {
  background: #f9f9f9;
  color: #333333;
  height: 100%;
  text-align: left;
  max-width: 85%;
  margin: auto;
  border-radius: 8px;
  padding: 2em;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
  font-family: sans-serif;


// .research-style {
//   background: #ffffff;
//   color: #222222;
//   height: 100%;
//   text-align: left;
//   max-width: 90%;
//   margin: auto;
  // margin-top: 5%;

  .subpage-heading {
    font-family: sans-serif;
    font-size: 32px;
    color: #096278;
    // margin-bottom: 20px;
    text-align: center;
  }

  .research-subheading p {
    font-family: sans-serif;
    font-size: 50px;
    color: #333333;
    // margin-bottom: 20px;
    margin-left: 0;
  }

  .research-subheading {
    font-family: "sans-serif";
    font-size: 18px;
    color: #096278;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
  .image {
    // width: 75%;
    // margin-bottom: 20px;
    width: 180px;
    height: 180px;
    border-radius: 5%;
    object-fit: cover; 
    margin-right: 15px; 
  }

  // .content-title {
  //   margin-bottom: 10px;
  // }

  p {
    font-family: "sans-serif";
    font-size: 18px;
    color: #333333;
    line-height: 1.45;
  }
}

@media only screen and (max-width: 600px) {
  .introduction,
  .research-style {
    padding: 3em 1em;
    // overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 800px;
    text-align: left;
    // margin-top: 10vh;

    .subpage-heading {
      font-family: sans-serif;
      font-size: 28px;
      color: #096278;
      // margin-bottom: 1em;
      // text-align: center;
    }

    .research-subheading p {
      font-family: sans-serif;
      font-size: 18px;
      color: #333333;
      margin-left: 0;
    }

    .research-subheading {
      font-family: sans-serif;
      font-size: 20px;
      color: #096278;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    // .image {
    //   // width: 75%;
    //   // margin-bottom: 20px;
    //   width: 25vh;
    //   height: 25vh;
    //   border-radius: 25px !important;
    //   object-fit: cover;
    //   margin-right: 15px;

    // }

    // .content-title {
    //   margin-bottom: 10px;
    // }

    p {
      font-family: sans-serif;
      font-size: 16px !important;
      color: #333333;
      line-height: 1.5;
    }

    h4{
      // margin-top: 2vh;
      font-size: 16px;
    }

    h3{
      // margin-top: 2vh;
      font-size: 18px;
    }
  }
}

/*-------------- footer style -----------------*/

.intro-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // overflow: hidden;
}

.content-footer {
  display: flex;
  margin-top: auto;
  padding: 4em 0;
  background: linear-gradient(100deg, #07b2d9, #30c8d9 40%, #f2dec4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  .social {
    padding: 0;
    list-style: none;
  }

  .contact-us {
    color: #162133;
    font-family: "Verdana";
    font-size: 20px;
    margin-bottom: 1vh;
  }

  .body-copy {
    color: #096278;
    font-weight: bold;
    font-family: "Bradley Hand";
    font-size: 25px;
  }
}
.social-link {
  color: #096278;
  text-decoration: none;
  font-weight: bold;
}

@media only screen and (min-width: 601px) and (max-width: 1400px) {
  .introduction,
  .research-style {
    // overflow: hidden;
    margin-top: 10vh;

    .subpage-heading {
      font-family: "Arial";
      font-size: 28px;
      color: #096278;
    }

    .research-subheading p {
      font-family: "Arial";
      font-size: 20px;
      color: #333333;
      // margin-bottom: 50px;
      margin-left: 0;
    }

    .research-subheading {
      font-family: "Arial";
      font-size: 20px;
      color: #096278;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .image {
      // width: 75%;
      // margin-bottom: 20px;
      width: 30vh; 
      height: 30vh; 
      border-radius: 25px;
      object-fit: cover; 
      margin-right: 15px; 
      margin-bottom: 3vh;
    }

    .content-title {
      margin-bottom: 10px;
    }

    h3{
      margin-top: 2vh;
    }

    p {
      font-family: "Arial";
      font-size: 18px;
      color: #333333;
      line-height: 1.45;
      margin-top: 2vh;
    }
  }
  .navbar-expand-custom {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-custom .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-custom .navbar-toggler {
    display: none;
  }
  .navbar-expand-custom .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media (max-width: 991px) {
  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}

/*-------------- resource bank style -------------*/
.resource-page {
  padding-left: 20em;
  padding-right: 20em;
  margin-top: 10vh;
}

.masthead-heading {
  margin-top: -0.2em;
  color: #e9e1d6;
  font-family: "Verdana";
  font-style: normal;
  font-weight: bold;
  font-size: 3em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-left: 80px;
  margin-right: 80px;
}

images .inlineImage {
  float: right;
  padding: 20px;
  margin-left: 15px;
  margin-bottom: 15px;
  width: 45%;
  height: auto;
}

.paragraph-bg-1 {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 30px;
}

.paragraph-bg-2 {
  padding: 25px;
  border-radius: 30px;
  background-image: url("../assets/bedroom.jpg");
}



@media (orientation: landscape) {

  .research-style {
    // overflow: hidden;
    margin-top: 20vh;
    max-width: 100%;
    padding: 10vh;
  
}

.introduction{
  padding: 10vh;
  max-width: 100%;
}
}

