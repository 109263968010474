
/* Main container covering the entire viewport */
.body-container {
    position: relative;
    min-height: 100vh; /* Full height of the viewport */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures no overflow from the pseudo-element */
}

/* Pseudo-element for the background image with opacity */
.body-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/unsubscribe_bg.jpeg') no-repeat center center / cover;
    opacity: 0.6; /* Set the opacity of the image to 80% */
    z-index: -1; /* Keeps the background behind the form */
}

/* Container for the form with a solid background */
.unsubscribe-container {
    position: relative; /* Ensures it is positioned above the pseudo-element */
    background-color: #ffffff; /* Solid white background for the form */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px; /* Maximum width of the form */
    z-index: 1; 
    font-family: Arial;

    h1 {
        color: #034891;
        text-align: center;
        margin-bottom: 10px;
    }


    p {
        font-size: 20px;
        color: #666;
        text-align: center;
        margin-bottom: 20px; /* Space before the form starts */
    }


}

.unsubscribe-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.unsubscribe-form label {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: Arial;
    line-height: 5vh;

}

.unsubscribe-form input[type="checkbox"] {
    margin-right: 10px;
    scale: 140%;
}

.unsubscribe-form button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 20px;
    font-family: Arial;
}

.unsubscribe-form button:hover {
    background-color: #0056b3;
}

.other-reason-input {
    width: 100%; /* Make the input box take the full width of the form */
    padding: 8px; /* Padding for better text visibility */
    margin-top: 10px; /* Space above the input box */
    border: 1px solid #ccc; /* Subtle border for the input */
    border-radius: 4px; /* Rounded corners for the input box */
    font-size: 16px;
    font-family: Arial;
}