

  @media  (max-width: 1400px) {
  .buy-credit{
    font-size: 20px;
    color:white;
    font-family: 'Arial', sans-serif;


  // Current Plan and Switch container
  .top-info {
    display: flex;
    justify-content: space-between; // Ensures space between elements
    align-items: center; // Aligns elements vertically in the center
    //width: 100%; // Ensures the container spans the full width of its parent
    padding: 0 20px; // Adds padding on both sides
    margin-left: 20px;
    flex-direction: column;
    background: #1b0248;
    margin-left: -10vw;
    
  
    .current-plan {
      font-size: px;
      font-weight: bold;
     // background: linear-gradient(to right, #56CCF2 0%, #2F80ED 51%);
      color: linear-20gradient(to right, #56CCF2 0%, #2F80ED 51%);
      //color: #fff; // Assuming you want white text color as per your design
      margin-top: 100px;
     // margin-left: 200px;
      position: relative;
      left:5vh;
    }    
  
    .auto-renew-toggle {
      display: flex;
      align-items: center; // Aligns the text and switch vertically
      margin-top:30px;
      margin-left: 20px;
      //z-index: 2000;
  
      span {
       // margin-right: 10px; // Space between the label "Auto Renew" and the switch
        font-size: 20px;
        color: #fff; // Text color for the "Auto Renew" label
      //  z-index: 2000;
      }
  
      .MuiSwitch-root {
        margin-top: 0; 
        width: 70px; // Adjust width if needed
        height: 34px; // Adjust height if needed
        z-index: 2000;
      }

      .MuiSwitch-switchBase {
        padding: 9px;
        z-index: 2000;
        &.Mui-checked {
          transform: translateX(20px); // Move thumb to the right when active
          color: #fff; // Thumb color when checked
          + .MuiSwitch-track {
            background-color: #4CAF50; // Green background when active

          }
         
        }
      }

      .MuiSwitch-thumb {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        width: 24px; // Increase thumb size
        height: 24px; 
      }

      .MuiSwitch-track {
        border-radius: 20px;
        background-color: #BDBDBD;
        opacity: 1;
        box-sizing: border-box;
        height: 20px; 
      }
    }
  
 
  }

     /* Main content container */
     .buy-credit-content {
  
      min-height: 100vh;
      max-width: 1500px;

      padding: 20px;
      background-color: #1b0248;
      //border-radius: 10px;
      box-shadow: 0 8px 16px rgba(255, 248, 248, 0.1);
      text-align: center;
      padding-top: 60px;

      p{
        color:white;
      }
  
      .pricing-container {
         // display: flex;
         // align-items: baseline; /* Aligns the text baselines of pricing and month */
          justify-content: center; /* Centers the pricing and month horizontally */
          font-size: 36px; /* Example size, adjust as needed */
          display: flex;
          //flex-direction: column;
         // width:200px;
         width: 120px;

         h1{
          font-size: 20px;
        }
      }

  
      .tab-title {
          font-size: 20px;
          font-weight: bold;
          margin: 10px 0;
          color: #FFFFFF; /* Adjust color if needed */
        }
  
      .pricing {
          font-size: 20px;
          font-weight: bold;
         // color: #9A9FBF; /* Lighter grey color for prices */
          margin: 5px 0 20px;
          display: inline;
          width: 200px;
        }
  
      .month {
          font-size: 16px;
          color: #e1d6d6; /* Lighter grey color for prices */
          margin: 5px 0 20px;
          display: inline;
        }
  
        .tokens {
          font-size: 16px;
          color: white; /* Lighter grey color for prices */
         // margin: 5px 0 20px;
          display: block;
          font-weight: bold;
          margin-top: 20px;
        
        }
    }
    
  }



/* Base styles for all buttons */

.tab-buttons {
  display: flex;
  justify-content: space-around; // Ensures tabs are evenly spaced
  width: 100%; 
  flex-wrap: wrap; 

  button {
    flex: 0 0 auto; // Prevents tabs from growing or shrinking
    width: calc(33.3% - 20px); // Adjusts width accounting for the gap; change the percentage as needed
    font-size: 24px;
    color: white;
    border: none;
    //padding: 15px 30px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    background-size: 200% auto;
    
    &:hover {
      background-position: right center; // Enhance hover effect
    }

}
button.basic {
  background-image: linear-gradient(to right, #56CCF2 0%, #2F80ED 51%);
}

button.basic.active {
  background-image: linear-gradient(to right, #2F80ED 0%, #1B4FAD 51%);
}


/* Styling for Standard plan */
button.standard {
  background-image: linear-gradient(to right, #6DD5FA, #2980B9);
}

button.standard.active {
  background-image: linear-gradient(to right, #2980B9, #176887);
}

/* Styling for Pro plan */
button.pro {
  background-image: linear-gradient(to right, #8E2DE2, #4A00E0);
}

.button.pro.active {
  background-image: linear-gradient(to right, #4A00E0, #190073);
}


/* Hover effects for all buttons */
button:hover {
  background-position: right center; /* shift the background */
}

/* Active button styling */
button.active {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
}

 /* Active button styling */
 /* Deactivated button styling */
button.deactivated {
background-image: linear-gradient(to right, #333333, #333333); /* Darker background for deactivated */
color: #aaaaaa; /* Dimmed text color */
cursor: not-allowed; /* Change cursor to indicate non-selectable */
opacity: 0.6; /* Make the button semi-transparent */
box-shadow: none; /* No shadow for deactivated buttons */
}
}

 
  .buy-credit-button {
    //background-color: #27ae60;
    background: linear-gradient(to right,  #2c00ff, #caf0ff, #2c00ff);
    color: #111a64;
    border: none;
    padding: 10px 20px;
    font-size: 24px;
    cursor: pointer;
    margin-top: 30px;
    border-radius: 5px;
    width: 50%;
    align-self: center;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  


.payment-form-container {
    background-color: #fff;
    padding: 5vh;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 60vh;
    margin: auto;
    margin-top:10vh;
    font-family:Arial;

    form {
        display: flex;
        flex-direction: column;
    }
    
    input[type="text"], input[type="radio"] {
        margin-bottom: 10px;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    
    input[type="text"] {
        width: 100%;
    }
    
    .card-details, .billing-address, .exp-cvc-group, .name-group {
        margin-bottom: 20px;
    }
    
    .exp-cvc-group input, .name-group input {
        width: calc(33.333% - 6px);
        display: inline-block;
        margin-right: 10px;
    }
    
    .exp-cvc-group input:last-child, .name-group input:last-child {
        margin-right: 0;
    }
    
    label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        color: #333;
    }
    
    button {
        background-color: #0061F2;
        color: white;
        padding: 12px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 2vh;
    }
    
    button:hover {
        background-color: #0051d2;
    }
    
    /* Hide the default Stripe CardElement styling */
    .StripeElement {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
    }
    
    .StripeElement--focus {
        border-color: #0061F2;
    }
    
}

//extra credits

$button-gradient-start: #333;
$button-gradient-end: #000;
$button-hover-gradient-start: #555;
$button-hover-gradient-end: #222;
$button-text-color: #fff;
$gold-color: rgba(255, 215, 0, 0.9);
$shadow-color: rgba(0, 0, 0, 0.5);

.extra-credits {
  //display: flex;
  justify-content: space-around; 
  flex-direction: column;
  //gap: 16px; // Adjust gap between buttons
  padding: 5px;
  background-color: #000; // Background color

  button {
    flex: 1 1 20%;
    background: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);
    border: none;
    border-radius: 10px;
    color: $button-text-color;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: background-color 0.3s, box-shadow 0.3s;

    &:hover {
      background: linear-gradient(145deg, $button-hover-gradient-start, $button-hover-gradient-end);
      cursor: pointer;
      box-shadow: 0 4px 8px $shadow-color;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 100%;
      background: $gold-color;
      clip-path: polygon(100% 0, 0 0, 100% 100%);
      transition: width 0.3s;
    }

    &:hover::before {
      width: 70px; // Slightly expand the golden clip path on hover
    }

    &.popular::before, &.best-value::before {
      content: attr(class); // Uses the class name as content dynamically
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
//payment success page

.success-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;

  .success-card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .success-icon {
      color: #4CAF50;
      font-size: 46px;
      i.checkmark {
        line-height: 50px;
      }
    }

    h2 {
      color: #333;
      font-size: 22px;
      margin-top: 24px;
    }

    p {
      color: #666;
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .start-btn {
      background-color: #6200ee;
      color: white;
      border: none;
      border-radius: 20px;
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      &:hover {
        background-color: #3700b3;
      }
    }
  }
}


 


  .tab-buttons {
    flex-direction: column; 
    align-items: center; 
    width: 100%;
    min-height: 80vh;
    max-width: 300px; 
    padding: 15px 30px; 
    font-size: 24px;
    margin: 10px auto;
    padding: 12px 10px;

    button {
      width: 90%; // Adjust the width to fit the container better
      margin: 10px 0; // Provides space between buttons vertically
      padding: 12px; // Adjust padding as needed
      margin-left: 10px;

    }
}

.buy-credit-button {
    width: 80%; // Increase or decrease based on desired button width
    padding: 12px 10px; // Adjust padding for better hand reach and aesthetics
    button {
      width: 90%; // Matches the width of tab buttons
      margin: 5px 0; // Adds vertical spacing between each button
  }
}

.extra-credits button {
    padding: 15px 10px; // Adjust padding to ensure buttons are not too large
    font-size: 14px; // Adjust font size for clarity
}
    .payment-form-container {
        background-color: #fff;
        padding: 5vh;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        width: 30vh;
        margin: auto;
        margin-top:10vh;
        font-family:Arial;
    
        form {
            display: flex;
            flex-direction: column;
        }
        
        input[type="text"], input[type="radio"] {
            margin-bottom: 10px;
            padding: 12px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }
        
        input[type="text"] {
            width: 100%;
        }
        
        .card-details, .billing-address, .exp-cvc-group, .name-group {
            margin-bottom: 20px;
        }
        
        .exp-cvc-group input, .name-group input {
            width: calc(33.333% - 6px);
            display: inline-block;
            margin-right: 10px;
        }
        
        .exp-cvc-group input:last-child, .name-group input:last-child {
            margin-right: 0;
        }
        
        label {
            display: block;
            margin-bottom: 5px;
            font-size: 14px;
            color: #333;
        }
   
      
        /* Hide the default Stripe CardElement styling */
        .StripeElement {
            width: 100%;
            padding: 12px;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
        }
        
        .StripeElement--focus {
            border-color: #0061F2;
        }
        
    }


}


@media (orientation: landscape) {
  body {
    transform: rotate(-90deg);
      font-family: 'Arial', sans-serif;
      background-color: #f4f4f8;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      overflow: auto !important;
    }

    .buy-credit{
      font-size: 20px;
      color:white;

      .buy-credit-content {
    
        min-height: 200vh;
        max-width: 1500px;
  
        padding: 20px;
        background-color: #1b0248;
        //border-radius: 10px;
        box-shadow: 0 8px 16px rgba(255, 248, 248, 0.1);
        text-align: center;
        padding-top: 60px;
        //margin-left: 450px;
        width: 100vw;

        p{
          color:white;
        }
    
    
        .pricing-container {
           // display: flex;
           // align-items: baseline; /* Aligns the text baselines of pricing and month */
            justify-content: center; /* Centers the pricing and month horizontally */
            font-size: 36px; /* Example size, adjust as needed */
            display: flex;
            //flex-direction: column;
           // width:200px;
           width: 220px;
        }
    
        .tab-title {
            font-size: 20px;
            font-weight: bold;
            margin: 10px 0;
            color: #FFFFFF; /* Adjust color if needed */
          }
    
        .pricing {
            font-size: 20px;
            font-weight: bold;
           // color: #9A9FBF; /* Lighter grey color for prices */
            margin: 5px 0 20px;
            display: inline;
            width: 200px;
          }
    
        .month {
            font-size: 16px;
            color: #e1d6d6; /* Lighter grey color for prices */
            margin: 5px 0 20px;
            display: inline;
          }
    
          .tokens {
            font-size: 16px;
            color: white; /* Lighter grey color for prices */
           // margin: 5px 0 20px;
            display: block;
            font-weight: bold;
            margin-top: 20px;
          
          }
          h1{
            font-size: 1em;
          }
      }
      
  
        /* Style for the buy button */
  
    // Current Plan and Switch container
    .top-info {
      display: flex;
      justify-content: space-between; // Ensures space between elements
      align-items: center; // Aligns elements vertically in the center
      //width: 100%; // Ensures the container spans the full width of its parent
      padding: 0 20px; // Adds padding on both sides
      margin-left: 20px;
      flex-direction: column;
      background: #1b0248;
      margin-left: -10vw;
    
      .current-plan {
        font-size: px;
        font-weight: bold;
       // background: linear-gradient(to right, #56CCF2 0%, #2F80ED 51%);
        color: linear-20gradient(to right, #56CCF2 0%, #2F80ED 51%);
        //color: #fff; // Assuming you want white text color as per your design
        margin-top: 100px;
       // margin-left: 200px;
        position: relative;
        top:10vh !important;
      }    
    
  
    
   
    }
    }
  
  
  
  /* Base styles for all buttons */
  
  .tab-buttons {
    display: flex;
    justify-content: space-around; // Ensures tabs are evenly spaced
    width: 100%; 
    flex-wrap: wrap; 
  
    button {
      flex: 0 0 auto; // Prevents tabs from growing or shrinking
      width: calc(33.3% - 20px); // Adjusts width accounting for the gap; change the percentage as needed
      font-size: 24px;
      color: white;
      border: none;
      //padding: 15px 30px;
      padding: 5px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s ease;
      background-size: 200% auto;
      
      &:hover {
        background-position: right center; // Enhance hover effect
      }
  
  }
  button.basic {
    background-image: linear-gradient(to right, #56CCF2 0%, #2F80ED 51%);
  }
  
  button.basic.active {
    background-image: linear-gradient(to right, #2F80ED 0%, #1B4FAD 51%);
  }
  
  
  /* Styling for Standard plan */
  button.standard {
    background-image: linear-gradient(to right, #6DD5FA, #2980B9);
  }
  
  button.standard.active {
    background-image: linear-gradient(to right, #2980B9, #176887);
  }
  
  /* Styling for Pro plan */
  button.pro {
    background-image: linear-gradient(to right, #8E2DE2, #4A00E0);
  }
  
  .button.pro.active {
    background-image: linear-gradient(to right, #4A00E0, #190073);
  }
  
  
  /* Hover effects for all buttons */
  button:hover {
    background-position: right center; /* shift the background */
  }
  
  /* Active button styling */
  button.active {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  }
  
   /* Active button styling */
   /* Deactivated button styling */
  button.deactivated {
  background-image: linear-gradient(to right, #333333, #333333); /* Darker background for deactivated */
  color: #aaaaaa; /* Dimmed text color */
  cursor: not-allowed; /* Change cursor to indicate non-selectable */
  opacity: 0.6; /* Make the button semi-transparent */
  box-shadow: none; /* No shadow for deactivated buttons */
  }
  }

  .auto-renew-toggle {
    display: flex;
    align-items: center; // Aligns the text and switch vertically
    margin-top:30px;
    margin-left: 20px;
   // z-index: 2000;
  }
  
   
    .buy-credit-button {
      //background-color: #27ae60;
      background: linear-gradient(to right,  #2c00ff, #caf0ff, #2c00ff);
      color: #111a64;
      border: none;
      padding: 10px 20px;
      font-size: 24px;
      cursor: pointer;
      margin-top: 30px;
      border-radius: 5px;
      width: 50%;
      align-self: center;
      transition: background-color 0.3s ease;
      font-weight: bold;
    }
    
  
  
  .payment-form-container {
      background-color: #fff;
      padding: 5vh;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
      width: 60vh;
      margin: auto;
      margin-top:10vh;
      font-family:Arial;
  
      form {
          display: flex;
          flex-direction: column;
      }
      
      input[type="text"], input[type="radio"] {
          margin-bottom: 10px;
          padding: 12px;
          border: 1px solid #ccc;
          border-radius: 4px;
      }
      
      input[type="text"] {
          width: 100%;
      }
      
      .card-details, .billing-address, .exp-cvc-group, .name-group {
          margin-bottom: 20px;
      }
      
      .exp-cvc-group input, .name-group input {
          width: calc(33.333% - 6px);
          display: inline-block;
          margin-right: 10px;
      }
      
      .exp-cvc-group input:last-child, .name-group input:last-child {
          margin-right: 0;
      }
      
      label {
          display: block;
          margin-bottom: 5px;
          font-size: 14px;
          color: #333;
      }
      
      button {
          background-color: #0061F2;
          color: white;
          padding: 12px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 16px;
          margin-top: 2vh;
      }
      
      button:hover {
          background-color: #0051d2;
      }
      
      /* Hide the default Stripe CardElement styling */
      .StripeElement {
          width: 100%;
          padding: 12px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
      }
      
      .StripeElement--focus {
          border-color: #0061F2;
      }
      
  }
  
  //extra credits
  
  $button-gradient-start: #333;
  $button-gradient-end: #000;
  $button-hover-gradient-start: #555;
  $button-hover-gradient-end: #222;
  $button-text-color: #fff;
  $gold-color: rgba(255, 215, 0, 0.9);
  $shadow-color: rgba(0, 0, 0, 0.5);
  
  .extra-credits {
    //display: flex;
    justify-content: space-around; 
    flex-direction: column;
    //gap: 16px; // Adjust gap between buttons
    padding: 5px;
    background-color: #000; // Background color
  
    button {
      flex: 1 1 20%;
      background: linear-gradient(145deg, $button-gradient-start, $button-gradient-end);
      border: none;
      border-radius: 10px;
      color: $button-text-color;
      padding: 15px 20px;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
      position: relative;
      overflow: hidden;
      width: 100%;
      transition: background-color 0.3s, box-shadow 0.3s;
  
      &:hover {
        background: linear-gradient(145deg, $button-hover-gradient-start, $button-hover-gradient-end);
        cursor: pointer;
        box-shadow: 0 4px 8px $shadow-color;
      }
  
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 100%;
        background: $gold-color;
        clip-path: polygon(100% 0, 0 0, 100% 100%);
        transition: width 0.3s;
      }
  
      &:hover::before {
        width: 70px; // Slightly expand the golden clip path on hover
      }
  
      &.popular::before, &.best-value::before {
        content: attr(class); // Uses the class name as content dynamically
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  //payment success page
  
  .success-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
    width: 100vw;
  
    .success-card {
      background: white;
      padding: 2rem;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .success-icon {
        color: #4CAF50;
        font-size: 46px;
        i.checkmark {
          line-height: 50px;
        }
      }
  
      h2 {
        color: #333;
        font-size: 22px;
        margin-top: 24px;
      }
  
      p {
        color: #666;
        margin-top: 8px;
        margin-bottom: 20px;
      }
  
      .start-btn {
        background-color: #6200ee;
        color: white;
        border: none;
        border-radius: 20px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        outline: none;
        &:hover {
          background-color: #3700b3;
        }
      }
    }
  }
  
  
      /* Main content container */

  
  
    .tab-buttons {
      flex-direction: column; 
      align-items: center; 
      width: 100%;
      min-height: 80vh;
      max-width: 300px; 
      padding: 15px 30px; 
      font-size: 24px;
      margin: 10px auto;
      padding: 12px 10px;


  
      button {
        width: 90%; // Adjust the width to fit the container better
        margin: 10px 0; // Provides space between buttons vertically
        padding: 12px; // Adjust padding as needed
        margin-left: 10px;
  
      }
  }
  
  .buy-credit-button {
      width: 80%; // Increase or decrease based on desired button width
      padding: 12px 10px; // Adjust padding for better hand reach and aesthetics
      button {
        width: 90%; // Matches the width of tab buttons
        margin: 5px 0; // Adds vertical spacing between each button
    }
  }
  
  .extra-credits button {
      padding: 15px 10px; // Adjust padding to ensure buttons are not too large
      font-size: 14px; // Adjust font size for clarity
  }
      .payment-form-container {
          background-color: #fff;
          padding: 5vh;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0,0,0,0.1);
          width: 30vh;
          margin: auto;
          margin-top:10vh;
          font-family:Arial;
      
          form {
              display: flex;
              flex-direction: column;
          }
          
          input[type="text"], input[type="radio"] {
              margin-bottom: 10px;
              padding: 12px;
              border: 1px solid #ccc;
              border-radius: 4px;
          }
          
          input[type="text"] {
              width: 100%;
          }
          
          .card-details, .billing-address, .exp-cvc-group, .name-group {
              margin-bottom: 20px;
          }
          
          .exp-cvc-group input, .name-group input {
              width: calc(33.333% - 6px);
              display: inline-block;
              margin-right: 10px;
          }
          
          .exp-cvc-group input:last-child, .name-group input:last-child {
              margin-right: 0;
          }
          
          label {
              display: block;
              margin-bottom: 5px;
              font-size: 14px;
              color: #333;
          }
     
        
          /* Hide the default Stripe CardElement styling */
          .StripeElement {
              width: 100%;
              padding: 12px;
              border: 1px solid #ccc;
              border-radius: 4px;
              box-sizing: border-box;
          }
          
          .StripeElement--focus {
              border-color: #0061F2;
          }
          
      }
  
  }
